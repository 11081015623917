import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { MemoryRouter as Router } from 'react-router-dom';
import Link from '@redoute/link';
import { Title1 } from '@redoute/titles';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "link"
    }}>{`Link`}</h1>
    <p>{`A Link is a styled component that can handle a internal or external redirect. If no destination we can use the component to handle a `}<em parentName="p"><inlineCode parentName="em">{`onClick`}</inlineCode></em>{` action such as a button.`}<br parentName="p"></br>{`
`}{`Style is coming from the theme and is applied the same on any of these cases. To custom a Link, you must wrap it with a parent.`}</p>
    <p>{`The Redoute Link component returns either :`}</p>
    <ul>
      <li parentName="ul">{`a component Link from `}<em parentName="li"><inlineCode parentName="em">{`react-router-dom`}</inlineCode></em>{` if used with prop `}<em parentName="li"><inlineCode parentName="em">{`to`}</inlineCode></em>{`, `}</li>
      <li parentName="ul">{`a `}<em parentName="li"><inlineCode parentName="em">{`<a href="">`}</inlineCode></em>{` if used with prop `}<em parentName="li"><inlineCode parentName="em">{`href`}</inlineCode></em></li>
      <li parentName="ul">{`a `}<em parentName="li"><inlineCode parentName="em">{`<span>`}</inlineCode></em>{` with same style if no props  `}</li>
    </ul>
    <Playground __position={0} __code={'<Router>\n  <p>\n    <Link to=\"/\">Internal Link</Link>\n  </p>\n  <p>\n    <Link to=\"/\" disabled>\n      Internal Link disabled\n    </Link>\n  </p>\n  <p>\n    <Link href=\"http://www.google.com\">External Link</Link>\n  </p>\n  <p>\n    <Link href=\"http://www.google.com\" disabled>\n      External Link disabled\n    </Link>\n  </p>\n  <p>\n    <Link onClick={() => alert(\'click\')}>Link with onClick</Link>\n  </p>\n  <p>\n    <Link disabled onClick={() => alert(\'click\')}>\n      Link with onClick disabled\n    </Link>\n  </p>\n  <Title1>\n    <Link>Custom styles for Link (Title1)</Link>\n  </Title1>\n  <p>\n    <Link hover>Link hover</Link>\n  </p>\n  <p>\n    <Link visited>Link visited</Link>\n  </p>\n  <p>\n    <Link active>Link active</Link>\n  </p>\n  <p>\n    <Link focus>Link focus</Link>\n  </p>\n</Router>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Router,
      Link,
      Title1,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <Router mdxType="Router">
        <p>
            <Link to='/' mdxType="Link">Internal Link</Link>
        </p>
        <p>
            <Link to='/' disabled mdxType="Link">Internal Link disabled</Link>
        </p>
        <p>
            <Link href='http://www.google.com' mdxType="Link">External Link</Link>
        </p>
        <p>
            <Link href='http://www.google.com' disabled mdxType="Link">External Link disabled</Link>
        </p>
        <p>
            <Link onClick={() => alert('click')} mdxType="Link">Link with onClick</Link>
        </p>
        <p>
            <Link disabled onClick={() => alert('click')} mdxType="Link">Link with onClick disabled</Link>
        </p>
        <Title1 mdxType="Title1">
            <Link mdxType="Link">Custom styles for Link (Title1)</Link>
        </Title1>
        <p>
            <Link hover mdxType="Link">Link hover</Link>
        </p>
        <p>
            <Link visited mdxType="Link">Link visited</Link>
        </p>
        <p>
            <Link active mdxType="Link">Link active</Link>
        </p>
        <p>
            <Link focus mdxType="Link">Link focus</Link>
        </p>
    </Router>
    </Playground>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Link} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      